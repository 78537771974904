<template>
    <div class='subProcessView'>
        <el-dialog
            class="subProcessView_popup"
            top="0"
            append-to-body
            :visible.sync="subProcessPopup"
            width="90%">
            <div class="subProcessView_popup_content">
                <div class="popup_content_bpmn">

                    <createBpmn :programmeDetails="programmeDetails" :notEdit="true"></createBpmn>
                </div>
                <div class="popup_content_btn" v-if="$route.query.edit != 2">
                    <el-button type="primary"  @click="editHandler">{{programmeDetails && programmeDetails.status == 1 ? '下架并编辑' : '编辑'}}</el-button>
                </div>

            </div>
        </el-dialog>
    </div>
</template>

<script>

// import createBpmn from './bpmn.vue'
import $http from '@/service/index'
export default {
    name: 'subProcessView',
    components: {
        createBpmn: () => import('./bpmn.vue')
    },
    props: {
        // 弹框控制条件
        popup: {
            type: Boolean,
            default: false
        },
        // 子流程数据
        processParams: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            programmeDetails: null, // 方案详情 
            subProcessPopup: false,
        }
    },
    computed: {},
    watch: {
        // 监听弹框改变
        popup(val) {
            if(val) {
                this.subProcessPopup = true
                this.getProgrammeDetails()

            }
        },

        subProcessPopup(val) {
            if (!val) {
                this.$emit('update:popup', false)
            }
        }
    },
    created () {

    },
    mounted () {

    },
    beforeCreate () { },
    beforeMount () { },
    beforeUpdate () { },
    updated () { },
    beforeDestroy () { },
    destroyed () { },
    activated () { },
    methods: {
        /**
         * 获取方案详情
         */
        async getProgrammeDetails() {
            if (!this.processParams?.programmeid) {
                this.$message.error('缺少子流程参数')
                return
            }
            const params = {
                data: {
                    id: this.processParams.programmeid,
                    needBpmn: true,
                },
                config: {
                    showLoading: true,
                    text: '加载中...',
                }
            };
            const res = await $http.getProgrammeDetails(params);

            if(res.code == '000000') {
                this.programmeDetails = res?.result ?? null;
            }
        },

        /**
         * 编辑
         */
        async editHandler() {
            if (this.programmeDetails.status == 1) {
                
                const params = {
                    data: {
                        id: this.processParams.programmeid,
                        published: '0'
                    },
                    config: {
                        showLoading: true,
                        text: '取消发布中...',
                    }
                };
                await $http.publishProgramme(params);
            }
            window.open(`${location.origin}/createProgramme?edit=1&id=${this.processParams.programmeid}`)
        }
    }
}
</script>

<style lang='less' scoped>

.subProcessView {
    .subProcessView_popup {
    }
}

/deep/ .el-dialog {

width: 70%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
margin-bottom: 0;
height: 90%;
.el-dialog__header {
    display: none;
}
.el-dialog__body {
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    .subProcessView_popup_content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .popup_content_bpmn {
            flex: 1;
            overflow: auto;
        }
        .popup_content_btn {
            display: flex;
            justify-content: center;
            padding-top: 10px;
        }
    }
}
}

</style>
